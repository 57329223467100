const navigation = () => {
  return [
    {
      title: 'Image Generator',
      path: '/imageCreator',
      icon: 'mdi:camera-plus' // Suggests image creation
    },
    {
      title: 'My Images',
      path: '/myImages',
      icon: 'mdi:image-multiple' // Represents multiple images or a gallery
    },
    {
      title: 'Gallery',
      path: '/publicImages',
      icon: 'mdi:image-multiple' // Represents multiple images or a gallery
    },
    {
      title: 'Pricing',
      path: '/pricing',
      icon: 'mdi:cash-multiple' // More directly related to money and transactions
    },
  ]
}

export default navigation
