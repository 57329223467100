// ** React Imports
import { useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Hooks Import
import { useAuth } from 'src/hooks/useAuth'

const AuthGuard = props => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()

  // Define public routes that do not require authentication
  const publicRoutes = ['/terms', '/privacy', '/pricingPage'];

  useEffect(() => {
    if (!router.isReady) {
      return
    }
    // Check if the current route is one of the public routes
    if (publicRoutes.includes(router.asPath)) {
      return; // Do not redirect if on public routes
    }
    if (auth.user === null && !window.localStorage.getItem('userData')) {
      router.replace({
        pathname: '/login',
        query: { returnUrl: router.asPath }
      })
    }
  }, [router.isReady, router.asPath, auth.user])

  if (auth.loading || auth.user === null) {
    return fallback
  }

  return <>{children}</>
}

export default AuthGuard
