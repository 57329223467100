// ** React Imports
import { useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Hooks Import
import { useAuth } from 'src/hooks/useAuth'

const GuestGuard = props => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()

  // Define public routes that should not redirect authenticated users
  const publicRoutes = ['/terms', '/privacy', '/pricingPage'];

  useEffect(() => {
    if (!router.isReady) {
      return
    }
    // Check if the current route is one of the public routes
    if (publicRoutes.includes(router.route)) {
      return; // Do not redirect if on public routes
    }
    if (window.localStorage.getItem('userData')) {
      router.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route])

  if (auth.loading || (!auth.loading && auth.user !== null)) {
    return fallback
  }

  return <>{children}</>
}

export default GuestGuard
