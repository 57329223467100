// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Typography sx={{ textAlign: 'center', mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        {`© ${new Date().getFullYear()} `}
        <Box component='span' sx={{ color: 'error.main', mx: 1 }}>Imagino</Box>
        {` by `}
        <LinkStyled target='_blank' href='https://app.intelligentservices.io/' sx={{ mx: 1 }}>
          Expert Services
        </LinkStyled>
        {!hidden && (
          <>
            <Box component='span' sx={{ mx: 2 }}>{` | `}</Box>
            <LinkStyled href='/terms' sx={{ mx: 1 }}>Terms of Service</LinkStyled>
            <Box component='span' sx={{ mx: 2 }}>{` | `}</Box>
            <LinkStyled href='/privacy' sx={{ mx: 1 }}>Privacy Policy</LinkStyled>
          </>
        )}
      </Typography>
    </Box>
  )
}

export default FooterContent
