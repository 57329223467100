import { initializeApp, getApps } from 'firebase/app'
import { getAuth, updatePassword, EmailAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions' // Import getFunctions

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDhlF7DGbdx2j4-2E5TIbLHzwftWZHjTNI",
  authDomain: "imagino-4ba75.firebaseapp.com",
  projectId: "imagino-4ba75",
  storageBucket: "imagino-4ba75.appspot.com",
  messagingSenderId: "620697122318",
  appId: "1:620697122318:web:791cbe0a398fedce54ee5f",
  measurementId: "G-Y2L0KED23S"
};


let firebaseApp
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig)
}

export const auth = getAuth()

export const db = getFirestore()

export const storage = getStorage()

// Initialize Firebase Functions
const functions = getFunctions(firebaseApp)

export { getAuth, updatePassword, EmailAuthProvider, functions }

export default firebaseApp
