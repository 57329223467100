const navigation = () => {
  return [
    {
      title: 'Image Generator',
      path: '/imageCreator',
      icon: 'mdi:camera-plus' // Suggests image creation
    },
    {
      title: 'My Images',
      path: '/myImages',
      icon: 'mdi:image-multiple' // Represents multiple images or a gallery
    },
    {
      title: 'Pricing',
      path: '/pricingPage',
      icon: 'mdi:cash-multiple' // More directly related to money and transactions
    },
    {
      title: 'Admin',
      path: '/admin',
      icon: 'mdi:account-cog' // Directly represents admin settings
    },
    {
      path: '/acl',
      action: 'read',
      subject: 'acl-page',
      title: 'Access Control',
      icon: 'mdi:security-network' // Represents network security, suitable for access control
    }
  ]
}

export default navigation
