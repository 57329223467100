const DefaultPalette = (mode, skin) => {
  // ** Vars
  const whiteColor = '#FFF'
  const lightColor = '76, 78, 100'
  const darkColor = '234, 234, 255'
  const mainColor = mode === 'light' ? lightColor : darkColor

  // Your custom colors
  const orange = '#FF8C00'
  const green = '#008000'
  const darkOrange = '#E65100'
  const offWhite = '#FDFDFD'
  const darkGreen = '#004D40'
  const gold = '#FFD700'
  const navyBlue = '#000080'
  const lightGrey = '#D3D3D3'
  const darkGrey = '#A9A9A9' // Lighter grey for text in dark mode
  const paleOrange = '#FFE0B2'
  const paleGreen = '#E0F2F1'

  // Adjustments for dark mode
  const darkModeBackground = '#121212' // Main background for dark mode
  const darkModePaper = '#1E1E1E' // Card backgrounds and similar elements in dark mode
  const darkModeText = '#E0E0E0' // Primary text color in dark mode

  // Define colors based on mode
  const backgroundColor = mode === 'light' ? offWhite : darkModeBackground
  const paperColor = mode === 'light' ? whiteColor : darkModePaper
  const textColor = mode === 'light' ? darkGrey : darkModeText

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: '#282A42',
      lightBg: '#F7F7F9',
      bodyBg: mode === 'light' ? '#F7F7F9' : '#282A42',
      trackBg: mode === 'light' ? '#F2F2F4' : '#41435C',
      avatarBg: mode === 'light' ? '#F1F1F3' : '#3F425C',
      tooltipBg: mode === 'light' ? '#262732' : '#464A65',
      tableHeaderBg: mode === 'light' ? '#F5F5F7' : '#3A3E5B'
    },
    mode: mode,
    common: {
      black: '#000',
      white: whiteColor
    },
    primary: {
      main: orange,
      contrastText: whiteColor
    },
    secondary: {
      main: green,
      contrastText: whiteColor
    },
    error: {
      main: darkOrange
    },
    background: {
      default: backgroundColor,
      paper: paperColor
    },
    text: {
      primary: textColor,
      secondary: lightGrey // Consider adjusting for dark mode if necessary
    },
    // Add custom colors
    darkGreen: {
      main: darkGreen
    },
    gold: {
      main: gold
    },
    navyBlue: {
      main: navyBlue
    },
    paleOrange: {
      main: paleOrange
    },
    paleGreen: {
      main: paleGreen
    },
    divider: `rgba(${mainColor}, 0.12)`,
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.05)`,
      hoverOpacity: 0.05,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  }
}

export default DefaultPalette
